import { formatInt, getCookie } from '@/utils/common'

export class UDate {
  #Date = null

  constructor (...args) {
    const date = new Date(...args)

    if (date.toString() === 'Invalid Date') {
      this.#Date = null
      return
    }

    this.#Date = date.valueOf() > 0 ? date : null
  }

  valueOf () {
    if (!this.#Date) {
      return null
    }

    return this.#Date.valueOf()
  }

  static formatDate (...args) {
    const fmt = new Intl.DateTimeFormat(undefined, {
      dateStyle: 'short'
    })

    const d = new UDate(...args)
    return d.valueOf() ? fmt.format(d) : ''
  }

  static formatDateTimeISO = (function () {
    const pad = function (num, length) {
      return (num >= 0) ? String(num).padStart((length !== undefined) ? length : 2, '0') : String(num)
    }

    return function (d, tzOffset) {
      if (d && d.valueOf() > 0) {
        if (tzOffset === undefined || tzOffset === null || tzOffset === false) {
          // Форматируем в UTC.
          return d.toISOString()
        }

        if (tzOffset === true) {
          // Форматируем в пользовательском часовом поясе по умолчанию.
          tzOffset = new Date().getTimezoneOffset()
        }

        // Сдвинем дату в указанный часовой пояс и вручную отформатируем в формате ISO.
        const d2 = new Date(d.valueOf() - tzOffset * 60 * 1000)
        return d2.getUTCFullYear() + '-' + pad(d2.getUTCMonth() + 1) + '-' + pad(d2.getUTCDate()) +
          'T' + pad(d2.getUTCHours()) + ':' + pad(d2.getUTCMinutes()) + ':' + pad(d2.getUTCSeconds()) + '.' + pad(d2.getUTCMilliseconds(), 3) +
          ((tzOffset === 0) ? 'Z' : (((tzOffset < 0) ? '+' : '-') + pad(Math.abs(tzOffset) / 60) + ':' + pad(Math.abs(tzOffset) % 60)))
      }
      return ''
    }
  })()

  static formatDateISO = (d, tzOffset) => {
    const convertToISOFormat = function (dateString) {
      const parts = dateString.split('.')
      return parts[2] + '-' + parts[1] + '-' + parts[0]
    }

    return this.formatDateTimeISO(new Date(convertToISOFormat(d)), tzOffset).substring(0, 10)
  }

  static formatDateTime (...args) {
    const fmt = new Intl.DateTimeFormat(undefined, {
      dateStyle: 'short',
      timeStyle: 'short'
    })

    const d = new UDate(...args)
    return d.valueOf() ? fmt.format(d) : ''
  }

  static formatTime (seconds) {
    let units
    const lang = getCookie('lang') || 'ru'
    if (lang === 'en') {
      units = {
        hours: ['hour', 'hours', 'hours'],
        minutes: ['minute', 'minutes', 'minutes'],
        seconds: ['second', 'seconds', 'seconds']
      }
    } else {
      units = {
        hours: ['час', 'часа', 'часов'],
        minutes: ['минута', 'минуты', 'минут'],
        seconds: ['секунда', 'секунды', 'секунд']
      }
    }

    const hours = Math.floor(seconds / UDate.hourInSeconds)
    seconds -= hours * UDate.hourInSeconds

    const minutes = Math.floor(seconds / UDate.minuteInSeconds)
    seconds -= minutes * UDate.minuteInSeconds

    return [
      (hours > 0) ? formatInt(hours, units.hours) : '',
      (minutes > 0) ? formatInt(minutes, units.minutes) : '',
      (seconds > 0) ? formatInt(seconds, units.seconds) : ''
    ].join(' ').trim()
  }

  static get weekInMilliseconds () {
    return 604800000
  }

  static get dayInMilliseconds () {
    return 86400000
  }

  static get hourInMilliseconds () {
    return 3600000
  }

  static get minuteInMilliseconds () {
    return 60000
  }

  static get secondInMilliseconds () {
    return 1000
  }

  static get quarterInMilliseconds () {
    return 7884000000
  }

  static get monthInMilliseconds () {
    return 2629800000
  }

  static get hourInSeconds () {
    return UDate.hourInMilliseconds / UDate.secondInMilliseconds
  }

  static get minuteInSeconds () {
    return UDate.minuteInMilliseconds / UDate.secondInMilliseconds
  }
}
